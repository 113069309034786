export default [
    {
        title: 'PacienteForm',
        load: () => import('@/modules/Care/Pacientes/PacienteForm.vue'),
    },
    {
        title: 'ResultadoForm',
        load: () => import('@/modules/Care/Resultados/ResultadoForm.vue'),
    },
    {
        title: 'DoctorForm',
        load: () => import('@/modules/Care/Doctores/DoctorForm.vue'),
    },
    {
        title: 'InstitucionForm',
        load: () => import('@/modules/Care/Instituciones/InstitucionForm.vue'),
    },
    {
        title: 'ExamenForm',
        load: () => import('@/modules/Care/Examenes/ExamenForm.vue'),
    },
    {
        title: 'ExamenTemplateForm',
        load: () => import('@/modules/Care/ExamenTemplate/ExamenTemplateForm.vue'),
    },
    {
        title: 'AgendaVIew',
        load: () => import('@/modules/Care/Agenda/Agenda.vue'),
    },
    {
        title: 'CitaForm',
        load: () => import('@/modules/Care/Citas/CitaForm.vue'),
    },
    {
        title: 'DatesDailyReport',
        load: () => import('@/modules/Care/Citas/DailyReport.vue'),
    },
    {
        title: 'Monitor de Asistencias',
        load: () => import('@/modules/Care/Citas/Monitor.vue'),
    },
    {
        title: 'Agenda del Día',
        load: () => import('@/modules/Care/Citas/TodayList.vue'),
    },
    {
        title: 'Agenda Especialista',
        load: () => import('@/modules/Care/Agenda/EventSetup.vue'),
    },
    {
        title: 'Agenda Setup',
        load: () => import('@/modules/Care/Agenda/AgendaSetup.vue'),
    },
    {
        title: 'ConsultaOftalmologiaForm',
        load: () => import('@/modules/Care/Consulta/OftalmologiaForm.vue'),
    },
    {
        title: 'MedicamentoForm',
        load: () => import('@/modules/Care/Medicamentos/MedicamentoForm.vue'),
    },
    {
        title: 'AyudaDiagnosticaForm',
        load: () => import('@/modules/Care/AyudasDiagnosticas/AyudaDiagnosticaForm.vue'),
    },
    {
        title: 'ConsultaTemplateForm',
        load: () => import('@/modules/Care/ConsultaTemplates/ConsultaTemplateForm.vue'),
    },
    {
        title: 'EspecialidadForm',
        load: () => import('@/modules/Care/Especialidades/EspecialidadForm.vue'),
    },
    {
        title: 'MotivosConsultaForm',
        load: () => import('@/modules/Care/MotivosConsulta/MotivoConsultaForm.vue'),
    },
    {
        title: 'ResultadoLegacyForm',
        load: () => import('@/modules/Care/ResultadoLegacy/ResultadoLegacyForm.vue'),
    },
    {
        title: 'ResultadoWrapper',
        load: () => import('@/modules/Care/Resultados/ResultadoWrapper.vue'),
    },
    {
        title: 'AsistenteDeConsulta',
        load: () => import('@/modules/Care/Consulta/AsistenteConsulta.vue'),
    },
    {
        title: 'ConsultaGastroenterologiaForm',
        load: () => import('@/modules/Care/Consulta/GastroenterologiaForm.vue'),
    },
];
